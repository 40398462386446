.products-slides-fulfillment {
  margin-bottom: 1rem;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 1.5rem;

  & > .products-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > .products-header--title {
      color: var(--color-blue);
      font-weight: 700;
      font-size: 1.4rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      &-ofertas {
        color: #f7432b;
      }
    }

    & > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--color-blue);
      font-weight: 600;
      font-size: 1rem;
      cursor: pointer;
    }
  }

  & > .products-slides {
    padding-top: 0 !important;
    ion-slides {
      display: flex;

      ion-slide {
        width: 12rem;
        margin-right: 1rem;
      }
    }
  }
}
