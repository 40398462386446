.home-provider-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;

    .guru-despacho-logo {
      width: auto;
      height: 2.5rem;
      margin-top: 0.6rem;
    }

    .star-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;

      & > span {
        color: var(--color-blue);
        font-weight: bold;
        margin-top: 0.2rem;
      }
    }

    ion-icon {
      color: var(--color-blue);
      cursor: pointer;
      font-size: 2rem;
      font-weight: 600;
    }

    i {
      cursor: pointer;
    }
  }
}

.mobile {
  padding: 40px 10px 20px 10px;
  height: 98px;
}

.web {
  padding: 20px 10px 20px 10px;
  height: 79px;
}
